export default class FunctionsClass {
  constructor() {}

 
  isInViewport (elem) {
      var bounding = elem.getBoundingClientRect();
      return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
  };

  __wC(name, value, days, dm) {
    // Write cookie
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/; domain=" + dm;
  }

  // Set RU lang cookie
  setRuLang() {
    var d = new Date();
    var time = d.getTime();
    __wC("ln", "ru", 365, settings.mainDomain);
  }

  // Right trim spaces аналог https://www.php.net/manual/ru/function.rtrim.php
  rtrim(str, chars) {
    chars = chars || "\\s";
    return str.replace(new RegExp("[" + chars + "]+$", "g"), "");
  }

  // Left trim spaces аналог https://www.php.net/manual/ru/function.ltrim.php
  ltrim(str, chars) {
    chars = chars || "\\s";
    return str.replace(new RegExp("^[" + chars + "]+", "g"), "");
  }

  // Trim spaces аналог https://www.php.net/manual/ru/function.trim.php
  trim(str, chars) {
    return this.ltrim(this.rtrim(str, chars), chars);
  }

  // Erase cookie
  // Я хз откуда тут writeCookie - скорее всего какойто плагин для jQuery
  // Нужно заменить нативным решением
  __eC(name) {
    writeCookie(name, "", -1);
  }

  __rC(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  // Track regional outbound links
  trackOutboundLinkClicks(event) {
    ga("send", "event", {
      eventCategory: "Переход региональную новость",
      eventAction: "click",
      eventLabel: event.target.href
    });
  }

  init() {
    window.__wC = this.__wC;
    window.setRuLang = this.setRuLang;
    window.rtrim = this.rtrim;
    window.ltrim = this.ltrim;
    window.trim = this.trim;
    window.__eC = this.__eC;
    window.__rC = this.__rC;
    window.isInViewport = this.isInViewport;
    window.trackOutboundLinkClicks = this.trackOutboundLinkClicks;
  }
}
