import axios from "axios";

export default class Vote {
  constructor() {
    this.voices = document.querySelectorAll(".voice");
  }

  onClick() {
    this.voices.forEach(el => {
      el.addEventListener("click", this.addVote);
    });
  }

  async addVote() {
    try {
      this.disabled = true;
      const voice_type = this.getAttribute("data-type");
      const goodResult = this.parentElement.children[1];
      const badResult = this.parentElement.children[3];
      let anotherVoice;

      const data = {
        uid: this.parentElement.getAttribute("data-uid"),
        content_id: this.parentElement.getAttribute("data-content-id"),
        voice_type,
        module_id: this.parentElement.getAttribute("data-module-id"),
        content_type: this.parentElement.getAttribute("data-content-type")
      };

      switch (voice_type) {
        case "1":
          anotherVoice = this.parentElement.children[2];
          break;
        case "2":
          anotherVoice = this.parentElement.children[0];
          break;
      }

      if (anotherVoice.disabled) {
        anotherVoice.disabled = false;
      }

      const response = await axios.post(
        `//api.online.ua/vote/voteadd.php`,
        data
      );

      const resultObj = JSON.parse(response.data);

      goodResult.textContent = resultObj.good;
      badResult.textContent = resultObj.bad;

    } catch (err) {
      return console.log(err);
    }
  }

  init() {
    this.onClick();
  }
}
