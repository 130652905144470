const Promise = require("promise-polyfill").default;
import axios from "axios";
const serialize = require("form-serialize");

export default class TV {
  constructor() {
    this.channelsContent = document.querySelector(".tv-main-channels-row");
    this.checkboxes = document.querySelectorAll(".tv-main-filters input");
    this.filterButtons = document.querySelectorAll(".tv-time button");
    this.dateSelect = document.querySelector("#dateSelect");
    this.channelsSaveBtn = document.querySelector("#channelsSaveBtn");
    this.channelsForm = document.querySelector("#channelsForm");
    this.channelsCheckboxes = document.querySelectorAll(
      "#channelsForm input[type='checkbox']"
    );
    this.modalCloseBtn = document.querySelector(
      "#channelsModal button[data-dismiss='modal']"
    );
    this.modalCancelBtn = document.querySelector(
      "#channelsModal button[data-id='cancel']"
    );
    this.modal = document.querySelector("#channelsModal");
    this.searchForm = document.querySelector("#tvProgramSearch");
    this.searchFormInput = document.querySelector(
      "#tvProgramSearch input[type='text']"
    );
    this.clearSearchBtn = document.querySelector("#clearTvSearch");
    this.allDayBtn = document.querySelector("button.tv-allday");
    this.alert = document.querySelector(".tv-search-alert");
  }

  onChange() {
    this.dateSelect.addEventListener("change", e => {
      location.assign(`${e.target.value}`);
    });
  }

  showAlert() {
    this.alert.classList.add("active");
    const self = this;
    setTimeout(() => {
      self.alert.classList.remove("active");
    }, 1500);
  }

  timeFilterShow(what) {
    const allDayArr = document.querySelectorAll(".prshow_allday");
    allDayArr.forEach(el => {
      if (!el.classList.contains("d-none")) {
        el.classList.add("d-none");
        el.parentNode.classList.add("d-none");
      }
      if (el.classList.contains("d-flex")) el.classList.remove("d-flex");
    });

    const curShowArr = document.querySelectorAll(`.prshow_${what}`);
    curShowArr.forEach(el => {
      if (this.searchFormInput.value.toLowerCase()) {
        const title = el.children[1].children[0].textContent.toLowerCase();
        if (
          !el.classList.contains("d-flex") &&
          title.includes(this.searchFormInput.value.toLowerCase())
        ) {
          el.classList.add("d-flex");
          if (el.parentNode.classList.contains("d-none"))
            el.parentNode.classList.remove("d-none");
        }
        if (
          el.classList.contains("d-none") &&
          title.includes(this.searchFormInput.value.toLowerCase())
        )
          el.classList.remove("d-none");
      } else {
        if (!el.classList.contains("d-flex")) el.classList.add("d-flex");
        if (el.classList.contains("d-none")) el.classList.remove("d-none");
        if (el.parentNode.classList.contains("d-none"))
          el.parentNode.classList.remove("d-none");
      }
    });

    const activeFilter = document.querySelector(".tv-time button.active");
    if (activeFilter.classList.contains("active"))
      activeFilter.classList.remove("active");

    const newFilter = document.querySelector(`.tv-time .tv-${what}`);

    newFilter.classList.add("active");
  }

  onHide() {
    this.modal.addEventListener(
      "hide.bs.modal",
      event => {
        this.returnPrevCheckboxesSate();
      },
      false
    );
  }

  onClick() {
    this.checkboxes.forEach(checkbox => {
      checkbox.addEventListener("click", e => {
        let programArr, colorclass;

        if (e.target.checked) {
          colorclass = checkbox.getAttribute("data-color");

          programArr = document.querySelectorAll(
            `.tv-channel-programm.${colorclass}`
          );

          programArr.forEach(program => {
            program.classList.add("selected");
          });
        } else {
          colorclass = checkbox.getAttribute("data-color");
          programArr = document.querySelectorAll(
            `.tv-channel-programm.${colorclass}`
          );
          programArr.forEach(program => {
            if (program.classList.contains("selected"))
              program.classList.remove("selected");
          });
        }
      });
    });

    this.filterButtons.forEach(button => {
      button.addEventListener("click", e => {
        const what = button.getAttribute("data-time");
        this.timeFilterShow(what);
      });
    });

    this.modalCancelBtn.addEventListener(
      "click",
      this.returnPrevCheckboxesSate
    );

    this.clearSearchBtn.addEventListener("click", () => {
      this.searchFormInput.value = "";

      const programsArr = document.querySelectorAll(".prshow_allday");

      programsArr.forEach(program => {
        if (program.classList.contains("d-none")) {
          program.classList.remove("d-none");
          program.classList.add("d-flex");
          if (program.parentNode.classList.contains("d-none")) {
            program.parentNode.classList.remove("d-none");
          }
        }
      });

      this.clearSearchBtn.classList.add("d-none");
      this.allDayBtn.click();
    });
  }

  returnPrevCheckboxesSate() {
    this.channelsCheckboxes.forEach(checkbox => {
      const dataId = checkbox.getAttribute("data-id");
      if (
        !document.querySelector(`.tv-main-channel[data-id='${dataId}']`) ||
        document.querySelector(`.tv-main-channel.d-none[data-id='${dataId}']`)
      ) {
        checkbox.checked = false;
      } else {
        checkbox.checked = true;
      }
    });
  }

  onSubmit() {
    this.searchForm.addEventListener("submit", e => {
      e.preventDefault();
      if (this.searchFormInput.value !== "" && this.searchFormInput.value.length > 1) {
        this.allDayBtn.click();
        this.checkboxes.forEach(checkbox => {
          checkbox.checked = false;
          const colorclass = checkbox.getAttribute("data-color");
          const programArr = document.querySelectorAll(
            `.tv-channel-programm.${colorclass}`
          );
          programArr.forEach(program => {
            if (program.classList.contains("selected"))
              program.classList.remove("selected");
          });
        });

        if (this.clearSearchBtn.classList.contains("d-none"))
          this.clearSearchBtn.classList.remove("d-none");

        const srearchQuery = this.searchFormInput.value.toLowerCase();
        const programsArr = document.querySelectorAll(".tv-channel-programm");

        programsArr.forEach(program => {
          var programTitle = program.children[1].children[0].textContent.toLowerCase();

          if (programTitle.includes(srearchQuery)) {
            if (program.classList.contains("d-none"))
              program.classList.remove("d-none");
            program.classList.add("d-flex");
          } else {
            if (program.classList.contains("d-flex"))
              program.classList.remove("d-flex");
            program.classList.add("d-none");
          }
        });
      } else {
        this.showAlert();
      }
    });

    this.channelsForm.addEventListener("submit", e => {
      e.preventDefault();
      const data = serialize(e.target, { hash: true });

      if (!data.c) {
        data.c = [];
      }

      axios
        .post("https://tv.online.ua/_ajax/settings/", data)
        .then(res => {
          const newChannels = res.data;

          if (newChannels && newChannels.length > 0) {
            const filteredChannels = newChannels.filter(channel => {
              if (
                document.querySelector(
                  `.tv-main-channel[data-id='${channel.id}']`
                )
              ) {
                return false;
              } else {
                return true;
              }
            });

            filteredChannels.forEach(channel => {
              let html = `<div class="card border-0 tv-main-channel" data-id="${channel.id}"><div class="tv-channel-title clear"><a class="d-flex align-items-center tv-channel-title-title" href="/channels/${channel.translit}/"><img class="tv-channel-logo mr-2" src="${channel.img}" alt="${channel.name}">${channel.name}</a>`;

              channel.programs.forEach(program => {
                html += `<div class="tv-channel-programm px-1 rounded prshow_allday${
                  program.isMorning ? " prshow_morning" : ""
                }${program.isEvning ? " prshow_evening" : ""}${
                  program.isNow ? " prshow_now" : ""
                }${
                  program.isDay ? " prshow_day" : ""
                } d-flex align-items-start tv-filter-141">
                  <div><span class="tv-channel-time align-top mr-2 text-14 text-muted">${
                    program.time
                  }</span></div>
                  <div><span class="tv-channel-prname align-top rounded text-14" title="${
                    program.title
                  }">${program.title}</span></div>
                </div>`;
              });

              html += `</div>`;

              this.channelsContent.insertAdjacentHTML("beforeend", html);
            });
          }
          this.updateChannelCheckboxesState();
          this.modalCloseBtn.click();
        })
        .catch(err => {
          console.log(err);
          this.updateChannelCheckboxesState();
          this.modalCloseBtn.click();
          return;
        });
    });
  }

  updateChannelCheckboxesState() {
    this.channelsCheckboxes.forEach(checkbox => {
      const dataId = checkbox.getAttribute("data-id");

      let channel;

      if (document.querySelector(`.tv-main-channel[data-id='${dataId}']`))
        channel = document.querySelector(
          `.tv-main-channel[data-id='${dataId}']`
        );
      if (channel) {
        if (checkbox.checked) {
          if (channel.classList.contains("d-none"))
            channel.classList.remove("d-none");
        } else {
          channel.parentNode.removeChild(channel);
        }
      }
    });
  }

  init() {
    this.onHide();
    this.onSubmit();
    this.onClick();
    this.onChange();
  }
}
