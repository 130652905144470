export class Calendar {
  constructor(){
    this.monthsArr = document.querySelectorAll('.bcalendar');
    this.btnNextArr= document.querySelectorAll('.cal-right');
    this.btnPrevArr= document.querySelectorAll('.cal-left');
  }

  onClick(){
    this.bindClickEvent(this.btnNextArr, 'next');
    this.bindClickEvent(this.btnPrevArr, 'prev');
  }

  bindClickEvent(arr, action) {
    arr.forEach(element => {
      element.addEventListener('click', this.rotateCalendarMonth.bind(this, action));
    });
  }

  rotateCalendarMonth(action){  
    const cur = document.querySelector('.bcalendar.active-month');
    if (action === 'next'){
        let next = cur.nextElementSibling;
        if (!cur.nextElementSibling){
            next = this.monthsArr[0]
        }
        cur.classList.toggle('active-month');
        next.classList.toggle('active-month');
    }
    
    if(action === 'prev'){
        let prev = cur.previousElementSibling;
        if (!cur.previousElementSibling){
          prev = this.monthsArr[this.monthsArr.length - 1];
        }
        cur.classList.toggle('active-month');
        prev.classList.toggle('active-month');
    }
  }

  init() {
    this.onClick();
  }
}