import { BtnScrollUp } from "./btnScrollUp.js";
require("es6-promise").polyfill();
require("isomorphic-fetch");
export class BtnLoad extends BtnScrollUp {
  constructor(timeout) {
    super();

    this.btn = document.querySelector("#btnAction");
    this.newsContainer = document.querySelector("ul#news");
    this.posts = document.querySelectorAll("ul#news li");
    this.btnLastNews = document.querySelector("#latest_news-tab");
    this.postTs = "";
    this.timeout = parseInt(timeout);
  }

  onClick(data) {
    this.btn.addEventListener("click", this.btnAction.bind(this, data));
  }

  async getArticles() {
    return await fetch(
      `${settings.newsDomain}_ajax/getlatest/?ts=${this.postTs}&lang=${settings.curLang}`
    )
      .then(response => {
        return response.json();
      })
      .then(myJson => {
        return myJson;
      })
      .catch(err => console.log(err));
  }

  checkFoUpdates() {
    if (this.posts.length > 0) {
      this.postTs = this.posts[0].getAttribute("data-ts");
      setInterval(() => {
        this.getArticles().then(json => {
          if (json.success == true) {
            this.postTs = json.data[0].date_ts;
            this.onClick(json.data);
            if (settings.curLang === "ru") {
              this.btn.textContent = "Обновить ленту";
            } else {
              this.btn.textContent = "Оновити стрічку";
            }
            this.btn.classList.add("show");
          }
        });
      }, this.timeout);
    }
  }

  btnAction(data) {
    let html = "";
    data.forEach(item => {
      html += `<li class="media position-relative mb-3 border-bottom pb-2 news-item" data-ts="${item.date_ts}"><div class="media-body"><a class="stretched-link h6 mb-1 d-flex flex-column justify-content-between h-100 title text-body" href="${item.url}">${item.title}<div><span class="font-weight-normal text-muted mr-2 text-14 align-middle opacity-60">${item.date}</span><span class="font-weight-normal text-14 text-muted opacity-60 align-middle">${item.category}</span></div></a></div></li>`;
    });

    this.newsContainer.insertAdjacentHTML("afterbegin", html);
    this.btn.classList.remove("show");

    if (this.btnLastNews) {
      this.btnLastNews.click();
    }
    if (settings.curLang === "ru") {
      this.btn.textContent = "Вверх";
    } else {
      this.btn.textContent = "Вгору";
    }
  }

  init() {
    this.checkFoUpdates();
  }
}
