export class Search {
  
  constructor(){
    this.isOpen = false;
    this.btn = document.querySelector("#main_search-btn");
    this.inputsContaier = document.querySelector("#main_search");
    this.searchInput = document.querySelector("#main_search-input");
    this.form = document.querySelector("#mainSearchForm");
  }

  onClick(){   
    if (!!this.btn) {
      this.btn.addEventListener('click', (e) => {
        e.preventDefault();
        if (this.isOpen == false) {
          this.isOpen = true;
          this.inputsContaier.classList.add("show");
          this.searchInput.focus();
  
        } else {
          if (this.searchInput.value != '') {
            this.form.submit()
  
          } else {
            this.isOpen = false;
            this.inputsContaier.classList.remove("show");
          }
        }
  
      })
    }
  }

  init(){
    this.onClick();
  }

}