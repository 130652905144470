export class HideRegNews {
    constructor() {
        this.btn = document.querySelector("#btnHide");
        if (this.btn) this.newsId = this.btn.getAttribute("data-news-id");
        if (this.btn) this.region = this.btn.getAttribute("data-news-region");
    }

    onClick() {
        this.btn.addEventListener("click", this.hideNews.bind(this));
    }

    async hideNews() {
        if (confirm("Вы уверены, что хотите спрятать эту новость?")) {
            return await fetch(
                `${settings.newsDomain}_ajax/hide_regional_news/?id=${this.newsId}`
            )
            .then(response => {
                window.location.replace(`${settings.newsDomain}${this.region}/`);
                return response.json();
            })
            .catch(err => console.log(err));
        }
    }

    init() {
        if (this.btn) {
            this.onClick();
        }
    }
}