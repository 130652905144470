export class mailCnt {
    constructor() {
        this.cnt_txt = document.querySelectorAll(".mail-cnt-text");
        this.cnt_icon = document.querySelectorAll(".mail-cnt-icon");

        this.delay = 15000;
    }
    updateMailWidget() {   
       this._checkMailCnt().then(data => {
            let that = this;
            if (data) {
                that.cnt_txt.forEach(function (el, index) {
                    el.innerHTML = data.text;
                });
     
                that.cnt_icon.forEach(function (el, index) {
                    if (!data.empty) {
                        el.innerHTML = '<use id="mbstatus" xlink:href="#mailbox-full"></use>';
                    } else {
                        el.innerHTML = '<use id="mbstatus" xlink:href="#mailbox-empty"></use>';
                    }
                });
            }
        });
    }
    async _checkMailCnt() {
        return await fetch(
            'https://mail.online.ua/_func/get_new_mail_json.php', {
                credentials: 'include'
            }
        )
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
    }
    init() {
        
         if (this.cnt_txt.length > 0) {
            let that = this;
            that.updateMailWidget();
            let timerMailCnt = setTimeout(function tick() {
                that.updateMailWidget();
                timerMailCnt = setTimeout(tick, that.delay);
            }, that.delay);
        }
    }
}