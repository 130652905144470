export class Clock {
  constructor() {
    this.timerCount = 0,
    this.isUpdate = 0,
    this.isTimeUpdate= 0,
    this.secondBlinkDelay = 333,
    this.DateTimeDelay = 1000
  }
  
  /**
   * Date time
   */
  getDateTime() {
      // settings.curDate.setTime(settings.curDate.getTime() + 1000);
      let day = lang['dayArray'][settings.curDate.getDay()];
      let month = lang['monthArray'][settings.curDate.getMonth()];
      let date = settings.curDate.getDate();
      let hours = settings.curDate.getHours();
      let mins = settings.curDate.getMinutes();

      if (hours < 10) {
        hours = "0" + hours
      };
      if (mins < 10) {
        mins = "0" + mins
      };
      let dayArr = document.querySelectorAll(".date-day");
      dayArr.forEach(item=> {       
        item.innerHTML = day + ',&nbsp; ';
      });

      let dateArr = document.querySelectorAll(".date-date");
      dateArr.forEach(item=> {
        item.innerHTML = " " + date + " " + month + ",&nbsp; ";
      });

      let timeArr = document.querySelectorAll(".date-time");
      timeArr.forEach(item=> {
        item.innerHTML = hours + "<span class=\"date-time-dot\">:</span>" + mins;
      });
       
      if ((hours == "00" && mins == "10" && this.isUpdate == 0) || (hours == "01" && mins == "10" && this.isUpdate == 0)) {
        this.isUpdate = 1;
      }
      if ((hours == "00" && mins == "11" && this.isUpdate == 1) || (hours == "01" && mins == "11" && this.isUpdate == 1)) {
        this.isUpdate = 0;
      }
      if (mins == "01" && this.isTimeUpdate == 1) this.isTimeUpdate = 0
  }

  /**
   * Blinking dots
   */
  startSecondBlink () {
    let dots = document.querySelector(".date-time-dot");
      if (dots)  {
        if (dots.className != "") {
          dots.className = "invisible";
        } 
      } return false
  }

  init() {
    if (typeof settings.curDate !== 'undefined' && typeof lang !== 'undefined') {
      
      let that = this;
      let timerDateTime = setTimeout(function tick() {
        that.getDateTime();
        timerDateTime = setTimeout(tick, that.DateTimeDelay);
      }, that.DateTimeDelay);

      let timerSecondBlink = setTimeout(function tick() {
        that.startSecondBlink();
        timerSecondBlink = setTimeout(tick, that.secondBlinkDelay);
      }, that.secondBlinkDelay);
      
    }
  }
}