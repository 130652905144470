import "../node_modules/bootstrap.native/dist/polyfill";
const bsn = require("bootstrap.native/dist/bootstrap-native-v4");
import "./scss/bootstrap.min.scss";
import "./scss/main.min.scss";
import "./scss/pages.min.scss";
import "./scss/press_release.min.scss";
// import "./scss/referendum.min.scss";
import "../node_modules/leaflet/dist/leaflet.css";
import "./assets/quotes.svg";
import "./assets/fridge.svg";
import "./assets/emailAdd.svg";

import Clipboard from "clipboard";
import Glide from "@glidejs/glide";
import { MobileMenu } from "./js/mobileMenu.js";
import { BtnScrollUp } from "./js/btnScrollUp.js";
import lazyLoadInit from "./js/lazyLoad.js";
import { BtnLoad } from "./js/btnLoad.js";
import { Counter } from "./js/counter.js";
import { Search } from "./js/search.js";
import { Clock } from "./js/clock.js";
// import { Referendum } from "./js/referendum.js";
import { mailCnt } from "./js/mailCnt.js";
 
import { Calendar } from "./js/calendar.js";

import TV from "./js/tv.js";
import FunctionsClass from "./js/functionsClass.js";
// import fitToParent from "fit-to-parent";
import Vote from "./js/vote";
import { HideRegNews } from "./js/hideRegNews.js";
import Valuta from "./js/valuta";
 
const mobileMenu = new MobileMenu();
const btnScrollUp = new BtnScrollUp();
const hideRegNews = new HideRegNews();
const btnLoad = new BtnLoad(10000);
const counter = new Counter(10);
const search = new Search();
const clock = new Clock();
// const referendum = new Referendum();
const mailcnt = new mailCnt();
const calendar = new Calendar();
const functionsClass = new FunctionsClass();
const tv = new TV();
const vote = new Vote();
const valuta = new Valuta();

const addTab = tabId => {
  const myTabs = document.getElementById(tabId);

  // let's give the initialization a JavaScript reference for the "target" option
  const myTabsCollection = myTabs.getElementsByTagName("A");

  // initialize the component for all items in the collection
  for (let i = 0; i < myTabsCollection.length; i++) {
    new bsn.Tab(
      myTabsCollection[i], // our target
      {
        // our options
        height: true
      }
    );
  }
};

const createModal = modalTriggerId => {
  // get a certain button trigger
  const myButton = document.getElementById(modalTriggerId);

  if (myButton) {
    // initiate Modal on a triggering element
    const myModalInstance = new bsn.Modal(myButton, {
      // options object
      //content: `<div class="modal-header border-0 bg-transparent pb-1"><button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div><div class="modal-body"><div class="card mx-auto mb--medium mw--sm-400 mw--lg-max"><div class="card-header text-center"><a class="h5 text-dark" href="//mail.online.ua/">Пошта</a></div><div class="card-body"><form><div class="form-group"><input class="form-control" type="text" aria-describedby="loginHelp" placeholder="Логін"/></div><div class="form-group"><input class="form-control" type="password" placeholder="Пароль"/></div><div class="form-group form-check"><input class="form-check-input" type="checkbox"/><label class="form-check-label" for="checkbox">Запам'ятати мене</label></div><button class="btn btn-warning mx-auto d-block w-75 mb-2" type="submit" aria-label="submit">Вхід</button><a class="btn btn-success mx-auto d-block w-75" href="//secure.online.ua/registration/?refMod=1">Реєстрація</a></form></div></div>`, // sets modal content
      //keyboard: false // we don't want to dismiss Modal on pressing Esc key
    });
  }
};

const createSlider = (mobile, tablet, desktop) => {
  new Glide(".glide", {
    type: "carousel",
    perView: parseInt(desktop),
    gap: 10,
    autoplay: 3000,
    hoverpause: true,
    focusAt: 1,
    breakpoints: {
      992: {
        perView: parseInt(tablet)
      },
      600: {
        perView: parseInt(mobile)
      }
    },
    classes: {
      slider: "glide--slider"
    }
  }).mount();
};

// const iframesAutoSizing = () => {
//   const iframesArr = document.querySelectorAll(
//     "#single_post_page .post--content iframe"
//   );
//   if (iframesArr.length > 0) {
//     iframesArr.forEach(vid => {
//       // sets iframe size
//       fitToParent(vid);

//       // Most of the time we will need to update on resize as well
//       window.addEventListener("resize", function() {
//         fitToParent(vid);
//       });
//     });
//   }
// };

const run = () => {
  
  //const btnElement = document.querySelector("#btnAction");
  const commentsBtn = document.querySelector("#commentsBtn");
  const mainPage = document.querySelector("#main_page");
  const weatherWidgetTabDiv = document.querySelector("#weather-widget-tab");
  const akkordyTabDiv = document.querySelector("#akkordy-tab");
  const singlePostPage = document.querySelector("#single_post_page");
  const counterPage = document.querySelector("#counter");
  const tvSchedulePage = document.querySelector("#tv-schedule");
  const newsSidebarTab = document.querySelector("#newsSidebarTab");
  const calendarPage = document.querySelector(".bcalendar");
  const wallpersSinglePage = document.querySelector("#wallpers_single");
  const voteBlock = document.querySelector(".vote_block");
  const valutaPage = document.querySelector("#valuta #calc_value");
  const keyboardPage = document.querySelector("#keyboard_page");
  // const tgPromo = document.querySelector("#tg-promo");
  const glideSlider = document.querySelector(".glide");
  lazyLoadInit();
  mobileMenu.init();
  // if (btnElement) {
  //   btnScrollUp.init();
  // }
  search.init();
  clock.init();
  // referendum.init();
  mailcnt.init();
  createModal("loginFormModalTrigger");
  functionsClass.init();
  if (mainPage) {
 
    if (glideSlider) {
     createSlider(1, 1, 1);
    }
    addTab("nav-tab");
    // addTab("mainTab");
  }
  if (weatherWidgetTabDiv) {
    addTab("weather-widget-tab");
  }
  if (akkordyTabDiv) {
    addTab("akkordy-tab");
  }
 
  if (counterPage) {
    counter.init();
  }

  if (tvSchedulePage) {
    tv.init();
  }

  if ("NodeList" in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function(callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }

  // if (commentsBtn) disqus.init();

  if (newsSidebarTab) addTab("newsSidebarTab");

  if (calendarPage) calendar.init();
  // iframesAutoSizing();
  if (wallpersSinglePage && glideSlider) {
    createSlider(3, 3, 3);
  }

  if (voteBlock) vote.init();
  if (valutaPage) valuta.init();
  if (keyboardPage) new Clipboard(".btn-clipboard");
};

run();