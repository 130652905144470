export class MobileMenu {
  constructor() {
    this.mobileMenu = document.querySelector("nav#mobileMenu");
    this.navbarBtn = document.querySelector("button.navbar-toggler");
    this.hamburgerSpans = document.querySelectorAll(".icon-bar");
    this.headerMenu = document.querySelector("header");
    this.backdrop = document.querySelector(".backdrop");
    this.curPosition = 0;
    this.btnUp = document.querySelector("#btnAction");
    this.pageWrap = document.querySelector(".page-wrap");
    this.menuToggle = this.menuToggle.bind(this);
    this.mailWidget = document.querySelector(".mail_widget");
  }

  onClick() {
    this.navbarBtn.addEventListener("click", this.menuToggle);
  }

  menuToggle() {
    if (!this.mobileMenu.classList.contains("open")) {
      this.curPosition =
        window.pageYOffset || document.documentElement.scrollTop;
    }

    this.backdrop.classList.toggle("d-block");
    this.mobileMenu.classList.toggle("open");
    this.navbarBtn.toggleAttribute("aria-expanded");

    if (this.btnUp.classList.contains("show")) {
      this.btnUp.classList.remove("show");
    } else {
      if (this.btnUp.textContent === "Оновити стрічку") {
        this.btnUp.classList.add("show");
      }
    }
    this.hamburgerSpans.forEach(function(item) {
      item.classList.toggle("change");
    });

    document.body.classList.toggle("stop--scroll");
    window.scroll(0, this.curPosition) ||
      document.documentElement.scrollTo(0, this.curPosition);
  }

  onScroll() {
    let lastScrollTop = 0;
    window.addEventListener("scroll", () => {
      if (this.getIEVersion() !== 11) {
        if (!this.mobileMenu.classList.contains("open")) {
          let scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;

          var ua = navigator.userAgent;
          var msie = ua.indexOf("MSIE ");

          if (msie > 0) {
            // If Internet Explorer, return version number
            console.log(
              parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)))
            );
          }
          if (scrollTop < 100) {
            this.headerMenu.style.top = 0;
            if(this.mailWidget) this.mailWidget.classList.add("top-50");
          } else {
            if (scrollTop >= lastScrollTop) {
              this.headerMenu.style.top = "-" + 100 + "%";
              if (this.mailWidget && this.mailWidget.classList.contains("top-50"))
                this.mailWidget.classList.remove("top-50");
            } else {
              this.headerMenu.style.top = 0;
              if(this.mailWidget) this.mailWidget.classList.add("top-50");
            }
            lastScrollTop = scrollTop;
          }
        }
      }
    });
  }

  getIEVersion() {
    var sAgent = window.navigator.userAgent;
    var Idx = sAgent.indexOf("MSIE");

    // If IE, return version number.
    if (Idx > 0)
      return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
    else return 0; //It is not IE
  }

  init() {
    this.onClick();
    this.onScroll();

    if (this.getIEVersion() == 11) {
      this.headerMenu.classList.remove("position-fixed");
      this.headerMenu.classList.remove("fixed-top");
      this.pageWrap.style.paddingTop = 0;
    }
  }
}
