export class Counter {
  constructor(num){
    this.counter = document.getElementById('counter');
    this.num = parseInt(num);
  }

  init(){
    let i = this.num
    
    this.counter.innerHTML = i;

    const interval = setInterval(() => {
      i--;
      this.counter.innerHTML = i;
    }, 1000);
  
    setTimeout(() => {
      clearInterval(interval);
      location.assign('https://mail.online.ua/');
    },this.num*1000)
  }
}