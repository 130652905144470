// docs https://github.com/verlok/lazyload
import LazyLoad from "vanilla-lazyload";

const logEvent = (eventName, element, debug) => {
  if (debug) console.log(
    Date.now(),
    eventName,
    element.getAttribute("data-src"),
    element.getAttribute("src")
  );
};

const lazyLoadOptions = {
  elements_selector: ".lazy",
  to_webp: true,

  callback_enter: element => {
    logEvent("ENTERED", element, false);
  },
  callback_load: element => {
    logEvent("LOADED", element, false);
  },
  callback_set: element => {
    logEvent("SET", element, false);
  },
  callback_error: element => { // Image load error
    logEvent("ERROR", element, false);
    element.src = "https://i.online.ua//img/placeholders/680_425_placeholder.jpg"; 
  }
};

const createLazyLoadInstance = () => {
  return new LazyLoad(lazyLoadOptions);
};

export default () => {
  document.addEventListener("DOMContentLoaded", createLazyLoadInstance);
};