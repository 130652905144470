import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

export class BtnScrollUp {
  constructor(){
    this.btn = document.querySelector("#btnAction");
    this.headerMenu = document.querySelector("header");
    this.newsContainer = document.querySelector("ul#news");
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  onClick(){
    this.btn.addEventListener("click", this.scrollToTop);
  }
  
  onScroll(){
    let lastScrollTop = 0;

    window.addEventListener("scroll", () => {

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      
      
      if (this.btn.textContent === "Вгору" || this.btn.textContent === "Вверх") {
        
        if (
          document.documentElement.scrollTop >
          (document.documentElement.scrollHeight / 1.5) ||
              window.pageYOffset >
                (document.documentElement.scrollHeight / 1.5) 
        ) {
          this.btn.classList.add("show");
        } else {
          if (scrollTop >= lastScrollTop) {
            this.btn.classList.remove("show");
          } else {
            if (
              window.pageYOffset > document.documentElement.offsetHeight / 3 ||
              document.documentElement.scrollTop >
                document.documentElement.offsetHeight / 3
            ) {
              this.btn.classList.add("show");
            } else {
              this.btn.classList.remove("show");
            }
          }
          lastScrollTop = scrollTop;
        }
      } 

      

      if (scrollTop > 100 && this.headerMenu.style.top == '0px') {
        this.btn.classList.add("showBelow");
      } else {
        this.btn.classList.remove("showBelow");

        if (scrollTop < 100) {
          this.btn.classList.add("showAtTop");
        } else {
          this.btn.classList.remove("showAtTop");
        }
      }    
    
    });
  }
  
  scrollToTop() {
    if (this.btn.textContent === "Оновити стрічку" || this.btn.textContent === "Обновить ленту" ) {
      window.scroll({
        top: this.newsContainer.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }

  init(){
    if(this.btn){
    if (settings.curLang === "ru") {
      this.btn.textContent = "Вверх";
    } else {
      this.btn.textContent = "Вгору";
    }
    }
    this.btn.classList.add("showAtTop");
    this.onClick();
    this.onScroll();
  }


}
