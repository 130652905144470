export default class Valuta {
  constructor() {
    this.calcInput = document.querySelector("#calc_value");
    this.select = document.querySelector("#wht");
    this.checkEmpty = this.checkEmpty.bind(this);
  }

  onClick() {
    this.calcInput.addEventListener("keyup", this.curcnt);
    this.calcInput.addEventListener("blur", this.checkEmpty);
    this.select.addEventListener("change", this.curcnt);
  }

  curcnt() {
    //check_empty();
    let sum = document.getElementById("calc_value").value;
    sum = sum.replace(",", ".");
    sum = sum.replace(" ", "");
    sum = sum * 1.0;
    let s = (sum * document.getElementById("wht").value).toFixed(6);

    document.getElementById("uah_v").innerHTML = (
      s / document.getElementById("uah_h").value
    ).toFixed(2);
    document.getElementById("usd_v").innerHTML = (
      s / document.getElementById("usd_h").value
    ).toFixed(2);
    document.getElementById("eur_v").innerHTML = (
      s / document.getElementById("eur_h").value
    ).toFixed(2);
    document.getElementById("rub_v").innerHTML = (
      s / document.getElementById("rub_h").value
    ).toFixed(2);
    document.getElementById("gbp_v").innerHTML = (
      s / document.getElementById("gbp_h").value
    ).toFixed(2);
    //document.getElementById('byr_v').innerHTML = (s / document.getElementById('byr_h').value).toFixed(2);

    return true;
  }

  checkEmpty() {
    if (document.getElementById("calc_value").value == "") {
      document.getElementById("calc_value").value = 1;
      this.curcnt();
    }
  }

  init(){
    this.onClick();
    this.curcnt();
  }
}
